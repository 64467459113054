<template>
  <div class="wrap">
    <div class="info">
      <div class="loading-wrapper" v-show="showLoading">
        <Loading></Loading>
      </div>
      <div class="login-wrapper" v-show="!showLoading">
        <h3 class="name" v-if="!this.classId"><i class="el-icon-edit" style="margin-right: 7px"></i>填写培训班信息</h3>
        <h3 class="name" v-else><i class="el-icon-edit" style="margin-right: 7px"></i>修改培训班信息</h3>
        <div class="content" >
          <el-row :gutter="100" >
            <el-form
              :model="classFrom"
              label-position="right"
              label-width="120px"
              :rules="rules3"
              ref="classFrom" >
              <el-col :span="12">
                <el-form-item label="培训班名称"  prop="className">
                  <el-input v-model="classFrom.className" style="width: 210px"></el-input>
                </el-form-item>
                <el-form-item label="课程类别"  prop="category">
                  <el-select v-model="classFrom.category"> <!---->
                    <div v-for="(i,k) in dictionaryList1" :key="k">
                      <el-option lable="" :value="i.value" style="width: 200px">{{i.value}}</el-option>
                    </div>
                  </el-select>
                </el-form-item>
                <el-form-item label="层级"  prop="level">
                  <el-select @change="qingjiaChange" v-model="classFrom.level">
                    <el-option v-for="(i,k) in dictionaryList2" :key="k" :value="i.sort" :label="i.value">{{i.value}}</el-option>
                  </el-select>
                  <!-- <el-select v-model="classFrom.level" @change="qingjiaChange">
                    <el-option lable="" v-for="(i,k) in dictionaryList2" :key="k" :value="i.value"></el-option>
                  </el-select> -->
                </el-form-item>
                <el-form-item label="主办单位" >
                  <el-cascader
                    :change-on-select="true"
                    :props="defaultParams"
                    :options="optionsTwo"
                    :clearable="true"
                    :show-all-levels="false"
                    v-model="classFrom.sponsor"
                    filterable></el-cascader>
                </el-form-item>
                  <el-form-item label="培训对象"  prop="trainObject">
                    <el-input type="textarea" :rows="5" resize="none" v-model="classFrom.trainObject" style="width: 100%"></el-input>
                    <!-- <el-input v-model="classFrom.trainObject" style="width: 100%"></el-input> -->
                  </el-form-item>
                <el-form-item label="培训内容"  prop="trainContent">
                  <el-input type="textarea" :rows="5" resize="none" v-model="classFrom.trainContent" style="width: 100%"></el-input>
                </el-form-item>
                <el-form-item label="培训时间"  prop="value1">
                  <el-date-picker v-model="classFrom.value1"
                  style="width: 100%;"
                                  type="datetimerange"
                                  range-separator="至"
                                  clearable
                                  value-format="yyyy-MM-dd HH:mm:ss"
                                  start-placeholder="开始日期"
                                  end-placeholder="结束日期">
                  </el-date-picker>
                </el-form-item>
                <el-form-item label="报名时间"  prop="registerTime">
                  <el-date-picker v-model="classFrom.registerTime"
                  style="width: 100%;"
                                  type="datetimerange"
                                  range-separator="至"
                                  clearable
                                  value-format="yyyy-MM-dd HH:mm:ss"
                                  start-placeholder="开始日期"
                                  end-placeholder="结束日期">
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="培训人数"  prop="trainNumber">
                  <el-input v-model="classFrom.trainNumber"></el-input>
                </el-form-item>
                <el-form-item label="办班地点"  prop="signLocation">
                  <el-input v-model="classFrom.signLocation">
                  </el-input>
                </el-form-item>
                <el-form-item label="培训机构"  prop="trainClass">
                  <el-input v-model="classFrom.trainClass"></el-input>
                </el-form-item>
                <el-form-item label="班主任:"  v-if="this.user">
                  <!-- <el-input v-model="classFrom.teacher"></el-input> -->
                  <el-select v-model="classFrom.userId" style="width: 100%">
                    <el-option v-for="(i,k) in teachersList" :key="k" :value="i.userId" :label="i.name"  @click.native="teacherChange(i)">{{i.name}}</el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="班主任联系方式"  prop="phone"  v-if="this.user">
                  <el-input v-model="classFrom.phone" maxlength="11" :disabled="true"></el-input>
                </el-form-item>
                <!-- <el-form-item label="报到地点"  class="one" prop="trainLocation">
                  <el-input v-model="classFrom.trainLocation">
                  </el-input>
                </el-form-item> -->
                <!-- <el-form-item label="请假级别" class="type" prop="leaveType">
                  <el-select v-model="classFrom.leaveType">
                    <el-option v-for="(i,k) in leaveTypeList" :key="k" :value="i.id" :label="i.value">{{i.value}}</el-option>
                  </el-select>
                </el-form-item> -->
              </el-col>
              <el-col :span="24">
                <el-form-item label="班级介绍" prop="presentation">
                  <el-input v-model="classFrom.presentation" type="textarea" rows="8"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="上课地点"  class="one" prop="classLocation">
                  <el-input v-model="classFrom.classLocation">
                  </el-input>
                </el-form-item>
                <el-form-item label="住宿地点"  class="one" prop="stay">
                  <el-input v-model="classFrom.stay">
                  </el-input>
                </el-form-item>
                <el-form-item label="用餐地点"  class="one" prop="dinnerPlace">
                  <el-input v-model="classFrom.dinnerPlace">
                  </el-input>
                </el-form-item>
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="报到日期"  prop="value3">
                       <el-date-picker
                          v-model="curriculumFrom.value3"
                          type="date"
                           @input="datetimeChange"
                          value-format="yyyy-MM-dd"
                          placeholder="选择日期" style="width:100%;overflow: hidden;">
                        </el-date-picker>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="报到时间"  prop="value4">
                      <el-time-picker
                            is-range
                            arrow-control
                            @change="timeChange"
                            v-model="curriculumFrom.value4"
                             value-format=" HH:mm:ss"
                            range-separator="至"
                            start-placeholder="开始时间"
                            end-placeholder="结束时间"
                            placeholder="选择时间范围"  style="width:100%">
                        </el-time-picker>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-form-item label="报到地点" required class="one" prop="trainLocation">
                  <el-input v-model="classFrom.trainLocation" placeholder="报到地点"></el-input>
                  <!--<span>经纬度</span><el-input v-model.number="classFrom.longitude" style="width: 150px"></el-input>-
                  <el-input v-model.number="classFrom.latitude" style="width: 150px"></el-input>
                   <baidu-map
                    :style="mapStyle"
                    ak="QlTx241xCLDlqyp7AEtpFtIYWiUNUZir"
                    :map-click="false"
                    :center="center"
                    :zoom="zoom"
                    :scroll-wheel-zoom="true"
                    @click="getClickInfo"
                    @ready="onBaiduMapReady"
                    class="map">
                    <bm-view style="width: 100%; height: 100%;" />
                    <bm-marker :position="{lng: center.lng, lat: center.lat}" :dragging="true" animation="BMAP_ANIMATION_BOUNCE" />
                    <bm-control>
                      <bm-auto-complete v-model="keyword" :sug-style="{zIndex: 999999}">
                        <el-input v-model="keyword" type="text" placeholder="请输入地名关键字" clearable>
                          <i slot="prefix" class="el-input__icon el-icon-search" />
                        </el-input>
                      </bm-auto-complete>
                    </bm-control>
                    <bm-geolocation anchor="BMAP_ANCHOR_BOTTOM_RIGHT" show-address-bar auto-location />
                    <bm-local-search :keyword="keyword" :auto-viewport="true" :panel="false" />
                  </baidu-map> -->
                </el-form-item>
                <el-form-item >
                  <div style="float:right">
                    <el-button type="primary" v-permission="['Dashboard:add']" @click="backClass()" :disabled="isDisable">返回</el-button>
                    <el-button v-if="!this.classId" type="primary" v-permission="['Dashboard:add']" @click="createClass('classFrom')" :disabled="isDisable">发布</el-button>
                    <el-button v-else type="primary" v-permission="['Dashboard:add']" @click="createClass('classFrom')" :disabled="isDisable">重新发布</el-button>
                  </div>
                </el-form-item>
              </el-col>
            </el-form>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

  import { createClass, GetAllById, Updata, showTreeByRedis, teacherList } from '@/api/user.js' // 导入方法
  import { getCookie } from '@/utils/base'
  import Loading from '@/components/loading/Loading.vue'
  // import { BaiduMap, BmControl, BmView, BmAutoComplete, BmLocalSearch, BmMarker, BmGeolocation } from 'vue-baidu-map'
  import { mapMutations } from 'vuex'
  //  import store from '@/store/index'
  export default {
    name: 'Dashbodrd',
    components: {
      Loading
      // BaiduMap,
      // BmControl,
      // BmView,
      // BmAutoComplete,
      // BmLocalSearch,
      // BmMarker,
      // BmGeolocation
    },

    data() {
      const checkClassName = (rule, value, callback) => { // 名字
        if (value === '') {
          callback(new Error('不能为空'))
        } else {
          callback()
        }
      }
      const checkCategory = (rule, value, callback) => { // 分类
        if (value === '') {
          callback(new Error('不能为空'))
        } else {
          callback()
        }
      }
      const checkLevel = (rule, value, callback) => { // 等级
        if (value === '') {
          callback(new Error('不能为空'))
        } else {
          callback()
        }
      }
      const checkStatus = (rule, value, callback) => { // 状态
        if (value === '') {
          callback(new Error('不能为空'))
        } else {
          callback()
        }
      }
      const checkSponsor = (rule, value, callback) => { // 主办单位
        if (value === '') {
          callback(new Error('不能为空'))
        } else {
          callback()
        }
      }
      const checkTrainObject = (rule, value, callback) => { // 培训对象
        if (value === '') {
          callback(new Error('不能为空'))
        } else {
          callback()
        }
      }
      const checkTrainContent = (rule, value, callback) => { // 培训内容
        if (value === '') {
          callback(new Error('不能为空'))
        } else {
          callback()
        }
      }
      const checkValue1 = (rule, value, callback) => { // 时间
        if (value === '') {
          callback(new Error('不能为空'))
        } else {
          callback()
        }
      }
      const checkTrainNumber = (rule, value, callback) => { // 培训人数
        if (value === '') {
          callback(new Error('不能为空'))
        } else {
          callback()
        }
      }
      const checkTrainLocation = (rule, value, callback) => { // 报到地点
        if (value === '') {
          callback(new Error('不能为空'))
        } else {
          callback()
        }
      }
      const checkTrainLocations = (rule, value, callback) => { // 报到地点
        if (value === '') {
          callback(new Error('不能为空'))
        } else {
          callback()
        }
      }
      const ckeckPhone = (rule, value, callback) => { // 联系人方式
        if (value === '') {
          callback(new Error('不能为空'))
        } else {
          callback()
        }
      }
      const ckeckRegisterTime = (rule, value, callback) => { // 班主任联系方式
        if (value === '') {
          callback(new Error('不能为空'))
        } else {
          callback()
        }
      }
      const checkcharge = (rule, value, callback) => { // 班主任姓名
        if (value === '') {
          callback(new Error('不能为空'))
        } else {
          callback()
        }
      }
      const checklevelType = (rule, value, callback) => { // 请假类别
        if (value === '') {
          callback(new Error('不能为空'))
        } else {
          callback()
        }
      }
      return {
        curriculumFrom: {
          value3: null,
          value4: null
        },
        isDisable: false,
        BMap: null, // 地图组件是否就绪
        showMapComponent: this.dialogVisible,
        keyword: '', // 地区搜索的信息
        mapStyle: {
          width: '100%',
          height: this.mapHeight + 'px'
        },
        center: { lng: 109.126747, lat: 21.486904 },
        zoom: 18,
        classId: this.$route.query.id,
        showLoading: false,
        user: '',
        teachersList: [], // 班主任列表
        table: [],
        dictionaryList1: [], // 存放字典信息
        dictionaryList2: [],
        dictionaryList3: [],
        dictionaryList4: [],
        detail: {},
        classFrom: {
          leaveType: '',
          teacher: '',
          className: '',
          category: '',
          level: '',
          status: '',
          sponsor: [],
          trainClass: '',
          trainObject: '',
          trainContent: '',
          value1: '',
          trainNumber: '',
          trainLocation: '',
          signLocation: '',
          phone: '',
          presentation: '',
          registerTime: '',
          classLocation: '',
          stay: '',
          dinnerPlace: ''
        },
        rules3: {
          leaveType: [{
            required: true,
            validator: checklevelType,
            trigger: 'change'
          }],
          teacher: [{ // 名字
            required: true,
            validator: checkcharge,
            trigger: 'change'
          }],
          className: [{ // 名字
            required: true,
            validator: checkClassName,
            trigger: 'change'
          }],
          category: [{ // 分类
            required: true,
            validator: checkCategory,
            trigger: 'change'
          }],
          level: [{ // 等级
            required: true,
            validator: checkLevel,
            trigger: 'change'
          }],
          status: [{ // 状态
            required: true,
            validator: checkStatus,
            trigger: 'change'
          }],
          sponsor: [{ // 主办方
            required: true,
            validator: checkSponsor,
            trigger: 'change'
          }],
          trainObject: [{ // 对象
            required: true,
            validator: checkTrainObject,
            trigger: 'change'
          }],
          trainContent: [{ // 培训内容
            required: true,
            validator: checkTrainContent,
            trigger: 'change'
          }],
          value1: [{ // 时间
            required: true,
            validator: checkValue1,
            trigger: 'change',
            type: 'string'
          }],
          value3: [{ // 时间
            required: true,
            validator: checkValue1,
            trigger: 'change',
            type: 'string'
          }],
          value4: [{ // 时间
            required: true,
            validator: checkValue1,
            trigger: 'change',
            type: 'string'
          }],
          trainNumber: [{ // 数量
            required: true,
            validator: checkTrainNumber,
            trigger: 'change'
          }],
          trainLocation: [{ // 地点
            required: true,
            validator: checkTrainLocation,
            trigger: 'change'
          }],
          signLocation: [{ // 办班地点s
            required: true,
            validator: checkTrainLocations,
            trigger: 'change'
          }],
          phone: [{ // 联系方式
            required: true,
            validator: ckeckPhone,
            trigger: 'change'
          },
          { min: 11, max: 11, message: '长度在 11字符', trigger: 'blur' }],
          registerTime: [{ // 联系方式
            required: true,
            validator: ckeckRegisterTime,
            trigger: 'change',
            type: 'string'
          }],
          classLocation: [{ required: true, message: '请填写上课地点', trigger: 'blur' }],
          stay: [{ required: true, message: '请填写住宿地点', trigger: 'blur' }],
          dinnerPlace: [{ required: true, message: '请填写用餐地点', trigger: 'blur' }]
        },
        optionsTwo: [],
        selectedOptions: [],
        defaultParams: {
          label: 'label',
          value: 'label',
          children: 'children'
        },
        leaveTypeList: [
          { id: 1, value: '一级审批' },
          { id: 2, value: '二级审批' }
        ]
      }
    },
    methods: {
      ...mapMutations({
        saveUser: 'SAVE_USER'
      }),
      /**
       *报到日期时间改变
      * **/
      datetimeChange(e) {
          console.log(e)
          const _this = this
          _this.curriculumFrom.value3 = e
          _this.$forceUpdate()
      },
      // 时间选择器
      timeChange(e) {
        console.log(e)
      },
      // ready事件：在你需要二次开发或手动控制其子组件，可以使用在此事件中使用返回的 BMap 类和 map 实例进行手动控制
      onBaiduMapReady(e) {
        // console.log('返回BMap类和map实例', e)
        // const that = this
        this.BMap = e.BMap
        if (this.BMap) {
          // 获取定位地址信息并赋值给声明变量
          // Geolocation 对象的 getCurrentPosition()、watchPosition()、clearWatch()
          const geolocation = new this.BMap.Geolocation()
          // 通过 getCurrentPosition() 方法：获取当前的位置信息
          geolocation.getCurrentPosition(res => {
            // console.log('返回详细地址和经纬度', res)
          })
        }
      },
      getClickInfo(e) { // 点击获取经纬度
        this.center.lng = e.point.lng
        this.center.lat = e.point.lat
        // 此时已经可以获取到BMap类
        if (this.BMap) {
          const that = this
          // Geocoder() 类进行地址解析
          // 创建地址解析器的实例
          that.classFrom.longitude = this.center.lng
          that.classFrom.latitude = this.center.lat
          const geoCoder = new this.BMap.Geocoder()
          geoCoder.getLocation(e.point, function(res) {
            // console.log('获取经纬度', e.point, '获取详细地址', res)
           // that.classFrom.trainLocation = res.address
           if (res.addressComponents.streetNumber === '' && res.surroundingPois.length > 0) {
              that.classFrom.trainLocation = res.surroundingPois[0].address
            } else {
              that.classFrom.trainLocation = res.address
            }
          })
        }
      },
      // 返回按钮  进行返回上一级操作
      backClass() {
        this.$router.go(-1)
      },
      // 发布按钮 进行创建班级
      createClass (classFrom) {
        console.log(this.curriculumFrom)
        this.$refs[classFrom].validate(valid => {
          if (valid) {
            if (this.classFrom.level === '县级') {
              this.classFrom.level = 1
            } else if (this.classFrom.level === '市级') {
              this.classFrom.level = 2
            } else if (this.classFrom.level === '自治区级') {
              this.classFrom.level = 3
            } else if (this.classFrom.level === '自治区级以上') {
              this.classFrom.level = 4
            }
            // if (this.classFrom.leaveType === '一级级别') {
            //   this.classFrom.leaveType = 1
            // } else if (this.classFrom.leaveType === '二级级别') {
            //   this.classFrom.leaveType = 2
            // }
             this.isDisable = true // 使按钮不可用
            setTimeout(() => {
              this.isDisable = false // 事件执行结束，恢复
            }, 7000) // 计时器：三秒后才能再次点击
            const trainClass = {
              // leaveType: this.classFrom.leaveType,
              teacher: this.classFrom.teacher,
              id: this.classId,
              className: this.classFrom.className,
              category: this.classFrom.category,
              level: this.classFrom.level,
              status: this.classFrom.status,
              sponsor: this.classFrom.sponsor + '',
              trainClass: this.classFrom.trainClass,
              trainObject: this.classFrom.trainObject,
              trainContent: this.classFrom.trainContent,
              opentTime: this.classFrom.value1[0],
              endTime: this.classFrom.value1[1],
              trainNumber: this.classFrom.trainNumber,
              trainLocation: this.classFrom.trainLocation,
              signLocation: this.classFrom.signLocation,
              phone: this.classFrom.phone,
              presentation: this.classFrom.presentation,
              registerOpenTime: this.classFrom.registerTime[0],
              registerEndTime: this.classFrom.registerTime[1],
             // longitude: this.center.lng,
             // latitude: this.center.lat,
              reportOpenTime: this.curriculumFrom.value3 + '' + this.curriculumFrom.value4[0],
              reportEndTime: this.curriculumFrom.value3 + '' + this.curriculumFrom.value4[1],
              classLocation: this.classFrom.classLocation,
              stay: this.classFrom.stay,
              dinnerPlace: this.classFrom.dinnerPlace,
              userId: this.classFrom.userId
            }
            createClass(trainClass).then(resp => { // 发布按钮
              // this[classFrom] = {}
              if (resp.data.code === '200') {
                this[classFrom] = {}
                this.$notify({
                  title: '成功',
                  message: '恭喜，班级发布成功。',
                  type: 'success'
                })
                this.$nextTick(() => { // 清空from表格
                  this.$refs[classFrom].resetFields()
                })
                // history.go(0) // 刷新当前页面
                 setTimeout(() => {
                  this.$router.push({
                    path: '/Classinformation'
                  })
                }, 500)
              } else if (resp.data.code === '400') {
                this.$notify({
                  title: '警告',
                  message: '请勿重复发布班级',
                  type: 'error'
                })
                this.$nextTick(() => { // 清空from表格
                  this.$refs[classFrom].resetFields()
                })
              } else if (resp.data.code === '401') {
                this.$notify({
                  title: '警告',
                  message: '输入的培训人数不能小于已报名人数的数量！',
                  type: 'error'
                })
              }
              // history.go(0) // 刷新当前页面
            })
          }
        })
      },
      GetAllById(id) { // 根据字典查询id
        GetAllById(id).then(resp => {
          if (resp.data.code === '0') {
            for (let i = 0; i < 4; i++) {
              if (id === 110) {
                this.dictionaryList1 = resp.data.data // 赋值
              } else if (id === 140) {
                this.dictionaryList2 = resp.data.data
              } else {
                this.dictionaryList4 = resp.data.data
              }
            }
          }
        })
      },
      // 选择请假列表 值变化
      qingjiaChange (val) {
         console.log(val)
        for (let i = 0; i < this.classFrom.level.length; i++) {
          if (this.classFrom.level[i].value === val) {
            this.classFrom.level = this.classFrom.level[i].sort
          }
        }
         console.log(this.classFrom.level.value)
      },
      // 修改from表单数据回显
      handleModify() {
        if (this.classId) {
          Updata(this.classId).then(resp => {
            this.classFrom = resp.data.data
            const that = this
            this.$set(that.classFrom, 'registerTime', ['' + that.classFrom.registerOpenTime, '' + that.classFrom.registerEndTime])
            this.$set(that.classFrom, 'value1', ['' + that.classFrom.opentTime, '' + that.classFrom.endTime])
            this.classFrom.sponsor = this.classFrom.sponsor.split(',')
            this.classFrom.level = Number(resp.data.data.level)
            this.curriculumFrom.value3 = resp.data.data.reportOpenTime.split(' ')[0]
            const data = []
            data[0] = resp.data.data.reportOpenTime.split(' ')[1]
            data[1] = resp.data.data.reportEndTime.split(' ')[1]
            this.$set(that.curriculumFrom, 'value4', [' ' + data[0], ' ' + data[1]])
            // this.$set(that.curriculumFrom, 'value3', ['' + resp.data.data.reportOpenTime, '' + resp.data.data.reportEndTime])
            console.log(this.curriculumFrom.value3)
            console.log(data)
            console.log(this.curriculumFrom.value4)
            // reportOpenTime: this.curriculumFrom.value3 + this.curriculumFrom.value4[0],
            // reportEndTime: this.curriculumFrom.value3 + this.curriculumFrom.value4[1],
            // this.$set(this.center, 'lng', Number.parseFloat(this.classFrom.longitude))
            // this.$set(this.center, 'lat', Number.parseFloat(this.classFrom.latitude))
          })
        }
      },
      // GetdepartmentId (id) {
      //   GetdepartmentId(id).then(resp => {
      //     // if (resp.data.code === '0') {
      //       // 将获得到的数据赋值给options
      //       // this.optionsTwo = resp.data.data
      //       // this.dictionaryList4 = resp.data.data
      //     // }
      //   })
      // },
      showTreeByRedis () {
        showTreeByRedis().then(resp => {
          if (resp.data.code === '200') {
            // 将获得到的数据赋值给options
            this.optionsTwo = resp.data.data.dictionaryTrees
            // this.dictionaryList4 = resp.data.data
          }
        })
      },
      teacherList() {
        teacherList().then(res => {
          this.teachersList = res.data.data
          // console.log(this.teachersList[0])
        })
      },
      teacherChange(e) {
        console.log(e)
        this.classFrom.phone = e.tel
        this.classFrom.userId = e.userId
      },
      GetInfo() {
      const data = getCookie('userMes')
      const a = JSON.parse(data)
      this.user = a.data.admin
      if (!this.user) {
        this.classFrom.userId = a.data.userId
        this.classFrom.phone = a.data.tel
        console.log(this.classFrom.userId)
        console.log(this.classFrom.phone)
      }
      // console.log(this.user.data)
      }
    },
    mounted() {
      this.GetAllById(110) // 课程类别
      this.GetAllById(140) // 培训班等级
      // this.GetAllById(130) // 培训班状态
      // this.GetAllById(140) // 培训对象
      this.handleModify()
      // this.GetdepartmentId(2000) // 开班单位名称
      this.showTreeByRedis() // 从缓存中部门
      this.GetInfo()
      this.teacherList()
    }
  }
</script>
<style scoped>
  .wrap {
    margin-top: -10px;
  }
  span {
    color: #409eff;
  }

  .info {
    margin-top: 5px;
    text-align: left;
  }

  .info .name {
    height: 40px;
    line-height: 40px;
    background: #e9e9e9;
    font-size: 13px;
    padding: 0 20px;
    margin: 0;
  }
  .map {
    width: 300px;
    height: 250px;
  }
  .info .content {
    padding: 10px 100px;
    background: #fff;
  }
</style>
